import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import { Form, Field } from "react-final-form";
import { useDataProvider } from "react-admin"; // <-- RA hook to fetch data
import { auth0 } from "./AdminPanel";

export type TNotificationType =
  | "NEW_BLOG_POST"
  | "NEW_COMMUNITY_POOL"
  | "MISSING_PICK_REMINDERS"
  | "COMPLETED_PICK_REMINDERS"
  | "GENERAL_NOTIFICATION";

// Adjust your form values to include postId.
type NotificationFormValues = {
  title: string;
  subtext: string;
  notificationType: TNotificationType;
  postId?: number; // We'll store the selected post's ID here
};

export default function Notification() {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // State to hold fetched blog posts
  const [blogPosts, setBlogPosts] = useState<any[]>([]);
  const dataProvider = useDataProvider();

  // Fetch existing posts (for "NEW_BLOG_POST" dropdown)
  useEffect(() => {
    dataProvider
      .getList("posts", {
        filter: {},
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "DESC" },
      })
      .then(({ data }) => {
        setBlogPosts(data);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
      });
  }, [dataProvider]);

  // Helper to build the payload
  const buildPayload = (values: NotificationFormValues) => {
    // Force the `url` logic based on notification type
    let url = "";
    let { postId } = values;

    if (values.notificationType === "NEW_COMMUNITY_POOL") {
      url = "mmafantasy://community";
      // If you don't need postId in this scenario, you can remove or reset it:
      postId = undefined;
    }

    if (values.notificationType === "NEW_BLOG_POST") {
      // We want a blank url, but have a postId
      url = "";
    }

    return {
      ...values,
      url,
      postId,
    };
  };

  const handleSendToMyself = async (
    values: NotificationFormValues,
    form: any
  ) => {
    setLoading(true);
    try {
      const token = await auth0.getTokenSilently();
      const payload = buildPayload(values);

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/admin/notification/myself",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      await response.json();
      form.reset(); // Clear the form
      setSuccessMessage("Notification sent successfully!");
    } catch (error) {
      console.error("Error sending notification:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendToEveryone = () => {
    setConfirmOpen(true);
  };

  const handleConfirm = async (values: NotificationFormValues, form: any) => {
    setConfirmOpen(false);
    setLoading(true);
    try {
      const token = await auth0.getTokenSilently();
      const payload = buildPayload(values);

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/admin/notification/everyone",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      await response.json();
      form.reset(); // Clear the form
      setSuccessMessage("Notification sent successfully!");
    } catch (error) {
      console.error("Error sending notification:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form<NotificationFormValues>
        onSubmit={() => {}}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <Field name="title">
                {({ input }) => (
                  <TextField
                    {...input}
                    label="Title"
                    fullWidth
                    margin="normal"
                  />
                )}
              </Field>
            </div>
            <div>
              <Field name="subtext">
                {({ input }) => (
                  <TextField
                    {...input}
                    label="Subtext"
                    fullWidth
                    margin="normal"
                  />
                )}
              </Field>
            </div>
            <div>
              <Field name="notificationType">
                {({ input }) => (
                  <TextField
                    {...input}
                    label="Notification Type"
                    select
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value="NEW_BLOG_POST">New Blog Post</MenuItem>
                    <MenuItem value="NEW_COMMUNITY_POOL">
                      New Community Pool
                    </MenuItem>
                  </TextField>
                )}
              </Field>
            </div>

            {/* Conditionally show the blog post dropdown if NEW_BLOG_POST */}
            {values.notificationType === "NEW_BLOG_POST" && (
              <div>
                <Field name="postId">
                  {({ input }) => (
                    <TextField
                      {...input}
                      label="Select Blog Post"
                      select
                      fullWidth
                      margin="normal"
                    >
                      {blogPosts.map((post) => (
                        <MenuItem key={post.id} value={post.id}>
                          {post.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </div>
            )}

            {/* Remove the explicit URL field entirely */}

            <div style={{ marginTop: "16px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleSendToMyself(values as NotificationFormValues, form)
                }
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Send to Myself"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSendToEveryone}
                style={{ marginLeft: "8px" }}
                disabled={loading}
              >
                Send to Everyone
              </Button>
            </div>

            <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
              <DialogTitle>Confirm Send to Everyone</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to send this notification to everyone?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setConfirmOpen(false)} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    handleConfirm(values as NotificationFormValues, form)
                  }
                  color="secondary"
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
      />

      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
      >
        <Alert onClose={() => setSuccessMessage(null)} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
